import React from 'react';
import Hero from './Hero';
import HomeSection1 from './HomeSection1';
import HomeSection2 from './HomeSection2';
import HomeSection3 from './HomeSection3';
import HomeSection4 from './HomeSection4';
import { Box } from '@chakra-ui/react';

const Home: React.FC = () => {
  return (
    <Box minHeight='100vh'>
      <Hero/>
      <HomeSection1/>
      <HomeSection2/>
       {/* <HomeSection3/> */}
      {/*<HomeSection4/> */}
    </Box>
  );
};

export default Home;