import React from 'react';
import { projects } from '../../Data/ProjectData';
import { Image, Grid, GridItem, Box, Flex, Heading, Text, Link, Stack, HStack } from '@chakra-ui/react';
import { BorderAction } from '@cloudinary/url-gen/actions/border';
import { px } from 'framer-motion';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const Reciplease: React.FC = () => {
  const id = 2;

  // Use `find` to get the project with the specific ID
  const project = projects.find((project) => project.id === id);

  const outerBoxStyles = {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '10px',
    background:
      `url(${project?.image}) center/cover no-repeat`,
  }

  const innerBoxStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    boxSize: 'full',
    color: 'white',
    borderRadius: '10px',
    textShadow: '0 0 20px black',
    fontWeight: 'bold',
    fontSize: '20px',
  }

  return (
    <>
      {!project ?
        <Text>
          Error
        </Text>
        :
        <>
          <Flex marginBottom={10} direction='column' justifyContent='center' alignItems='center' w='100%'>
            <Box w={{ base: '100%', md: '70%', xl: '50%' }}>
                <Heading justifyContent='center' alignItems='center' as='mark' fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}>
                  {project.title}
                </Heading>
                <Text textAlign='center' w='100%' fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} mb={{ base: '5', xl: '10' }} mt={{ base: '5', xl: '10' }}>
                  {project.description}
                </Text>
                <HStack w='100%' spacing={5} justifyContent='center' alignItems='flex-start' mb={{ base: '5', xl: '10' }}>
                  <Link href='https://github.com/thisisgurpal/food-api' isExternal color='teal.500' fontWeight='bold' fontSize='20px'>Project Code <ExternalLinkIcon mx='2px' /></Link>
                </HStack>
                <Image src={project.image} borderRadius={10} mt={10} mb={10}/>
                <Text >
                <Text w='100%' fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} mb={{ base: '5', xl: '10' }} mt={{ base: '5', xl: '10' }}>
                  Description:
                </Text>
                This is an application built using JavaScript and React that allows you to search for your desired food and see how it’s made including details about measurements and ingredients. This was a paired promgramming project with Steve Dobbie, and the time period to complete was 48 hours. I was responsible for ensuring that the back end was correctly retrieving information from an external API, designing the home page, designing the individual recipe pages and also building the filter functions to choose a recipe.
                  <Text w='100%' fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} mb={{ base: '5', xl: '10' }} mt={{ base: '5', xl: '10' }}>
                  Brief:
                </Text>
                <ul>
                <li>The brief is to build an application that consumes a public API and has several components.</li>
                </ul>
                <Text w='100%' fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} mb={{ base: '5', xl: '10' }} mt={{ base: '5', xl: '10' }}>
                Technologies and tools used:
                </Text>
                  <ul>
                    <li>React</li>
                    <li>JavaScript</li>
                    <li>Axios</li>
                    <li>CSS</li>
                    <li>React Router DOM</li>
                    <li>Visual Studio Code</li>
                  </ul>
                  <br /><br />
                  If you're interested in the detail of the process and build (Documentation & Code) - <Link href='https://github.com/thisisgurpal/food-api' isExternal color='teal.500' fontWeight='bold'>Click here</Link>.
                </Text>
              </Box>
          </Flex>
    </>
      }
    </>
  );
};

export default Reciplease;
