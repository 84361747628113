import React from 'react';
import { projects } from '../../Data/ProjectData';
import { Image, Grid, GridItem, Box, Flex, Heading, Text, Link, Stack, HStack } from '@chakra-ui/react';
import { BorderAction } from '@cloudinary/url-gen/actions/border';
import { px } from 'framer-motion';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const PremierLeagueScrapeDashProject: React.FC = () => {
  const id = 5;

  // Use `find` to get the project with the specific ID
  const project = projects.find((project) => project.id === id);

  const outerBoxStyles = {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '10px',
    background:
      `url(${project?.image}) center/cover no-repeat`,
  }

  const innerBoxStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    boxSize: 'full',
    color: 'white',
    borderRadius: '10px',
    textShadow: '0 0 20px black',
    fontWeight: 'bold',
    fontSize: '20px',
  }

  return (
    <>
      {!project ?
        <Text>
          Error
        </Text>
        :
        <>
          <Flex marginBottom={10} direction='column' justifyContent='center' alignItems='center' w='100%'>
            <Box w={{ base: '100%', md: '70%', xl: '50%' }}>
                <Heading justifyContent='center' alignItems='center' as='mark' fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}>
                  {project.title}
                </Heading>
                <Text textAlign='center' w='100%' fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} mb={{ base: '5', xl: '10' }} mt={{ base: '5', xl: '10' }}>
                  {project.description}
                </Text>
                <HStack w='100%' spacing={5} justifyContent='center' alignItems='flex-start' mb={{ base: '5', xl: '10' }}>
                  <Link href='https://public.tableau.com/app/profile/gurpalgohler/viz/PremierLeagueDashboard_17095666895140/PremierLeagueDashboard' isExternal color='teal.500' fontWeight='bold' fontSize='20px'>Dashboard <ExternalLinkIcon mx='2px' /></Link>
                  <Link href='https://github.com/thisisgurpal/Premier-League-Analytics' isExternal color='teal.500' fontWeight='bold' fontSize='20px'>Project Code <ExternalLinkIcon mx='2px' /></Link>
                </HStack>
                <Image src={project.image} borderRadius={10} mt={10} mb={10}/>
                <Text >
                <Text w='100%' fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} mb={{ base: '5', xl: '10' }} mt={{ base: '5', xl: '10' }}>
                  Description:
                </Text>
                  I wanted to learn web scraping. I thought about certifications. I thought about text books. But no, I think the best way to learn is by doing.
                  <br /><br />
                  I chose a passion which is football. Scraped 2000+ lines of data, along with multiple attributes, from the Premier League website.
                  <br /><br />
                  Python, selenium and pandas were my weapons of choice. The cleaning and transformations were done using SQL. Then it was time to create the visualisations, where I used Tableau.
                  <br /><br />
                  The result is an interactive dashboard, updated weekly, showing the statistics you want to know about your favourite players and teams this season.
                  <br /><br />
                  Is Rodri the top passer per match?
                  Does Virgil Van Dijk have the best tackle success rate?
                  What is the shooting accuracy of Erling Haaland?
                  What is the trend of points over the seasons for Manchester United?
                  <br /><br />
                  <Link href='https://public.tableau.com/app/profile/gurpalgohler/viz/PremierLeagueDashboard_17095666895140/PremierLeagueDashboard' isExternal color='teal.500' fontWeight='bold'>Click here</Link> to go to the live dashboard and get answers to all these questions, and more.
                  <br /><br />
                  If you're interested in the detail of the process and build (Documentation & Code) - <Link href='https://github.com/thisisgurpal/Premier-League-Analytics' isExternal color='teal.500' fontWeight='bold'>Click here</Link>.
                </Text>
              </Box>
          </Flex>
    </>
      }
    </>
  );
};

export default PremierLeagueScrapeDashProject;
