import React from 'react';
import { projects } from '../../Data/ProjectData';
import { Image, Grid, GridItem, Box, Flex, Heading, Text, Link, Stack, HStack } from '@chakra-ui/react';
import { BorderAction } from '@cloudinary/url-gen/actions/border';
import { px } from 'framer-motion';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const TinyHabits: React.FC = () => {
  const id = 3;

  // Use `find` to get the project with the specific ID
  const project = projects.find((project) => project.id === id);

  const outerBoxStyles = {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '10px',
    background:
      `url(${project?.image}) center/cover no-repeat`,
  }

  const innerBoxStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    boxSize: 'full',
    color: 'white',
    borderRadius: '10px',
    textShadow: '0 0 20px black',
    fontWeight: 'bold',
    fontSize: '20px',
  }

  return (
    <>
      {!project ?
        <Text>
          Error
        </Text>
        :
        <>
          <Flex marginBottom={10} direction='column' justifyContent='center' alignItems='center' w='100%'>
            <Box w={{ base: '100%', md: '70%', xl: '50%' }}>
                <Heading justifyContent='center' alignItems='center' as='mark' fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}>
                  {project.title}
                </Heading>
                <Text textAlign='center' w='100%' fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} mb={{ base: '5', xl: '10' }} mt={{ base: '5', xl: '10' }}>
                  {project.description}
                </Text>
                <HStack w='100%' spacing={5} justifyContent='center' alignItems='flex-start' mb={{ base: '5', xl: '10' }}>
                  <Link href='https://github.com/thisisgurpal/Tiny-Habits' isExternal color='teal.500' fontWeight='bold' fontSize='20px'>Project Code <ExternalLinkIcon mx='2px' /></Link>
                </HStack>
                <Image src={project.image} borderRadius={10} mt={10} mb={10}/>
                <Text >
                <Text w='100%' fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} mb={{ base: '5', xl: '10' }} mt={{ base: '5', xl: '10' }}>
                  Description:
                </Text>
                  In collaboration with Borahm Cho and Pete Livermore, we embarked on an exciting mission to develop a unique habit app. This platform invites users to participate in upcoming 30-day habit challenges, harnessing the power of React, Node JS, Express JS, and MongoDB – the formidable MERN stack.
                  <br /><br />
                  Immerse yourself in a world of possibilities as you register, create your personalised profile, and embark on a journey of self-improvement by joining captivating events. Capture your triumphant moments as you post your completed habits, engage with other users through thoughtful comments, and spread motivation with uplifting likes.
                  <Text w='100%' fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} mb={{ base: '5', xl: '10' }} mt={{ base: '5', xl: '10' }}>
                  Brief:
                </Text>
                <ul>
                <li>Time frame for the build was 8 days.</li>
                <li>Build a full-stack application using an Express API to serve our data from a Mongo database.</li>
                <li>Consume our API with a seperate front end built in React.</li>
                <li>Implement CRUD (Create, Read, Update, Delete) functionality.</li>
                <li>Have a visually impressive design.</li>
                </ul>
                <Text w='100%' fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} mb={{ base: '5', xl: '10' }} mt={{ base: '5', xl: '10' }}>
                Technologies and tools used:
                </Text>
                  <ul>
                    <li>Git + Github</li>
                    <li>JavaScript</li>
                    <li>MongoDB</li>
                    <li>Mongoose</li>
                    <li>JWT</li>
                    <li>Express</li>
                    <li>Node.js</li>
                    <li>React</li>
                    <li>Chakra UI</li>
                    <li>Axios</li>
                    <li>CSS</li>
                    <li>Cloudinary Image Upload API</li>
                    <li>Heroku deployment</li>
                    <li>Visual Studio Code</li>
                  </ul>
                  <br /><br />
                  If you're interested in the detail of the process and build (Documentation & Code) - <Link href='https://github.com/thisisgurpal/Tiny-Habits' isExternal color='teal.500' fontWeight='bold'>Click here</Link>.
                </Text>
              </Box>
          </Flex>
    </>
      }
    </>
  );
};

export default TinyHabits;
