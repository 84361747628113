import { Text, Box, Grid, GridItem, Flex, Link as ChakraLink } from '@chakra-ui/react';
import { BorderAction } from '@cloudinary/url-gen/actions/border';
import { px } from 'framer-motion';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom'

const Hero: React.FC = () => {

  const outerBoxStyles = {
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
    borderRadius: '100%',
    background:
      'url(https://res.cloudinary.com/dmbkzjwzq/image/upload/v1723978577/portfolio-main/image00001_-_Copy_rpgsbt.jpg) center/cover no-repeat',
  }

  const innerBoxStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    boxSize: 'full',
    color: 'white',
    borderRadius: '10px',
    textShadow: '0 0 20px black',
    fontWeight: 'bold',
    fontSize: '20px',
  }

  return (
    <Box marginBottom={10}>
      <Grid
        templateRows={{ base: 'repeat(3, 1fr)', xl: 'repeat(1, 1fr)' }}
        templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(4, 1fr)' }}
        gap={2}
      >
        <GridItem borderRadius={10} pt={{ base: 0, md: 10 }} pb={{ base: 5, md: 10 }} colSpan={{ base: 1, xl: 3 }} rowSpan={{ base: 2, xl: 1 }}>
          <Text fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}>
            Hi, I'm Gurpal Gohler. I specialise in <ChakraLink isExternal href='https://github.com/thisisgurpal'><Text as='mark'>Data Analytics</Text></ChakraLink> and <ChakraLink isExternal href='https://github.com/thisisgurpal'><Text as='mark'>Data Engineering</Text></ChakraLink>. My background is in Mathematics, Statistics and Software Engineering. I enjoy writing <Text as='mark'><ChakraLink as={ReactRouterLink} to='/blogs'>blogs</ChakraLink></Text> and working on <Text as='mark'><ChakraLink as={ReactRouterLink} to='/projects'>projects</ChakraLink></Text>. In my free time, you'll find me pushing my body to it's limits by <Text as='mark'><ChakraLink as={ReactRouterLink} to='/'>running marathons</ChakraLink></Text>.
          </Text>
        </GridItem>
        <GridItem borderRadius={10} rowSpan={1} colSpan={1}>
          <Flex w='100%' h='100%' justifyContent='center' alignItems='center'>
            <Box sx={outerBoxStyles} w={{ base: '100px', xl: '200px' }} h={{ base: '100px', xl: '200px' }}>
            </Box>
          </Flex>

        </GridItem>
      </Grid>
    </Box>

  );
};

export default Hero;